<template>
  <div class="examine_detail" v-loading="loading">
    <back />

    <div class="content globle_border">
      <el-form ref="form1" class="form" :model="form" label-position="left" label-width="170px" disabled>
        <div class="title1">查看商超信息</div>
        <div class="shop_img">
          <el-form-item label="营业执照">
            <el-image class="busilicense" fit="cover" :src="form.businessLicenseUrl" :preview-src-list="[form.businessLicenseUrl]"></el-image>
          </el-form-item>
          <el-form-item label="店铺logo" class="shop" v-if="form.status == 1">
            <el-image class="busilicense" fit="cover" :src="form.logoUrl" :preview-src-list="[form.logoUrl]"></el-image>
          </el-form-item>
        </div>
        <el-form-item label="公司名称">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.creditCode"></el-input>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="form.shopName"></el-input>
        </el-form-item>
        <el-form-item label="关联学校">
          <el-input v-model="form.schoolName"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contactMan"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.contactTel"></el-input>
        </el-form-item>
        <el-form-item label="商超地址">
          <el-input v-model="form.local"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <div style="width: 650px; box-sizing: border-box" v-html="form.introduction" class="Runecloth"></div>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-input v-model="status[form.status]" :style="form.status == 0 ? 'color:red' : ''"></el-input>
        </el-form-item>
        <el-form-item label="审核不通过原因" v-if="form.status == 2 || form.status == 3">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
        <el-form-item label="审核时间" v-if="form.status == 1 || form.status == 2 || form.status == 3">
          <el-input v-model="form.updatedTime"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      loading: false,
      submitLoding: false,
      dialogVisible: false,
      colorStatus: ["red", "green", "gray"],
      status: ["待审核", "审核通过", "审核不通过", "审核不通过"],
      mapStatus: "",
      form: {
        // businessLicenseUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getSupplierDetail()
  },
  methods: {
    getSupplierDetail() {
      this.loading = true
      this.$axios
        .get(`${this.$api.shopDetails}/${this.id}`)
        .then(res => {
          const { result } = res.data
          this.form = result
          // this.mapStatus = this.form.status == 0 ? '待审核' : this.form.status == 1 ? '审核通过' : this.form.status == 2 ? '审核不通过' : '审核通过'
          this.form.local = result.province + result.city + result.area + result.address
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.examine_detail {
  .content {
    padding: 10px 190px;
  }

  .shop_img {
    display: flex;

    .shop {
      margin-left: 276px;
    }
  }

  .form {
    .red {
      /deep/.el-input__inner {
        color: #eb2500 !important;
      }
    }

    .gray {
      /deep/.el-input__inner {
        color: #68706e !important;
      }
    }

    .Runecloth {
      width: 1000px;
      min-height: 40px;
      border-radius: 10px;
      border: 1px solid #e5e7e6;
      padding: 0px 10px;
      cursor: not-allowed;

      /deep/ img {
        object-fit: contain;
        width: 100%;
      }
    }

    .title1,
    .title2 {
      position: relative;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .title1::before,
    .title2::before {
      content: "";
      position: absolute;
      top: 0;
      left: -12px;
      width: 4px;
      height: 22px;
      background-color: #069bbc;
    }

    .title2 {
      margin-top: 60px;
    }

    /deep/.el-input {
      width: 650px;
    }

    /deep/ .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 40px;
    }

    /deep/.el-textarea {
      width: 650px;
    }

    /deep/ .el-input__inner,
    /deep/.el-textarea__inner {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #e5e7e6;
    }

    /deep/ .el-input.is-disabled .el-input__inner,
    /deep/ .el-textarea.is-disabled .el-textarea__inner {
      color: #1a1a1a;
    }

    .logo {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }
  }

  .times {
    padding: 30px;
    margin-top: 30px;
    box-sizing: border-box;

    .exinfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      /deep/ .el-form-item__label {
        padding-right: 16px;
      }

      /deep/ .el-form-item {
        margin: 0;
      }
    }

    .lose_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .label {
        width: 90px;
        padding-right: 16px;
      }

      .label,
      /deep/ .el-textarea__inner {
        color: #eb2500 !important;
      }

      /deep/ .el-form-item__label {
        padding-right: 16px;
      }

      /deep/ .el-form-item {
        display: block;
        margin: 0;
      }
    }

    .status {
      width: 202px;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #e5e7e6;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #069bbc;
    }

    .gray {
      color: #68706e;
    }
  }

  .btn {
    text-align: center;
    margin: 30px 0;

    .el-button {
      width: 150px;
      margin: 0 20px;
    }
  }
}
</style>
